<template>
  <div>
    <b-form @submit.prevent="addUser">
      <b-form-group label="Prénom" label-for="first-name-input">
        <b-form-input
            id="first-name-input"
            v-model="$v.user.firstName.$model"
            type="text"
            required
            placeholder="Renseignez un prénom"
            :state="$v.user.firstName.$dirty ? !$v.user.firstName.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.user.firstName.$invalid">
          <span v-if="!$v.user.firstName.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.user.firstName.alpha">Vous devez renseigner un prénom valide</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Nom" label-for="last-name-input">
        <b-form-input
            id="last-name-input"
            v-model="$v.user.lastName.$model"
            type="text"
            required
            placeholder="Renseignez un nom"
            :state="$v.user.lastName.$dirty ? !$v.user.lastName.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.user.lastName.$invalid">
          <span v-if="!$v.user.lastName.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.user.lastName.alpha">Vous devez renseigner un nom valide</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Email" label-for="email-input">
        <b-form-input
            id="email-input"
            v-model="$v.user.email.$model"
            type="email"
            required
            placeholder="Renseignez une adresse email"
            :state="$v.user.email.$dirty ? !$v.user.email.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.user.email.$invalid">
          <span v-if="!$v.user.email.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.user.email.email">Vous devez renseigner une addresse email valide</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="$v.user.admin.$model" name="check-button" switch>
          Administrateur
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <template slot="label">
          Posts
          <font-awesome-icon
              icon="plus-circle"
              :style="{ color: 'green' }"
              v-b-modal.add-posts-modal
          />
        </template>
        <UserPostsList :posts="user.posts" @remove="removePost"/>
        <b-form-invalid-feedback :state="!$v.user.posts.$invalid">Ajouter au moins un poste</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <template slot="label">
          Leads
          <font-awesome-icon
              icon="plus-circle"
              :style="{ color: 'green' }"
              v-b-modal.add-leads-modal
          />
        </template>
        <UserPostsList :posts="user.leads" @remove="removeLead"/>
      </b-form-group>
      <b-button type="submit" variant="primary" :disabled="$v.$invalid">
        Envoyer
      </b-button>
    </b-form>
    <AddPostsModal :posts="nonUserPosts" @add="addPost"/>
    <AddLeadsModal :leads="nonUserLeads" @add="addLead"/>
  </div>
</template>

<script>

import {email, required} from "vuelidate/lib/validators";
import {boolean, name} from "@/validators";

import AddPostsModal from "./modals/AddPostsModal";
import AddLeadsModal from "./modals/AddLeadsModal";
import UserPostsList from "./UserPostsList";

export default {
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        admin: false,
        posts: [],
        leads: [],
      },
    };
  },
  computed: {
    nonUserPosts() {
      return this.posts.filter(
          (post) => !this.user.posts.some((userPost) => post.uid === userPost.uid)
      );
    },
    nonUserLeads() {
      return this.posts.filter(
          (post) => !this.user.leads.some((userLead) => post.uid === userLead.uid)
      );
    },
  },
  validations: {
    user: {
      firstName: {
        required,
        name,
      },
      lastName: {
        required,
        name,
      },
      email: {
        required,
        email,
      },
      admin: {
        boolean,
      },
      posts: {
        required,
      },
    },
  },
  components: {
    AddPostsModal,
    AddLeadsModal,
    UserPostsList,
  },
  methods: {
    addPost(post) {
      this.user.posts.push(post);
      this.$v.user.posts.$touch();
    },
    addLead(lead) {
      this.user.leads.push(lead);
    },
    removePost(postToRemove) {
      this.user.posts = this.user.posts.filter(
          (userPost) => userPost.uid !== postToRemove.uid
      );
      this.$v.user.posts.$touch();
    },
    removeLead(leadToRemove) {
      this.user.leads = this.user.leads.filter(
          (userLead) => userLead.uid !== leadToRemove.uid
      );
    },
    addUser() {
      this.$store
          .dispatch("users/addUser", this.user)
          .then(async () => {
            await this.$swal.fire({
              icon: "success",
              title: "Utilisateur ajouté",
            });
            await this.$router.push({name: "users"});
          })
          .catch((e) => {
            this.$swal.fire({
              icon: "error",
              title:
                  "Une erreur est survenue lors de la création de l'utilisateur",
              text: e.message,
            });
          });
    },
  },
};
</script>

