<template>
  <AddUserForm v-if="ready" :posts="posts"/>
</template>

<script>
import {mapState} from "vuex";
import AddUserForm from "@/components/users/AddUserForm";

export default {
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState({
      posts: (state) => state.posts.all,
    }),
  },
  components: {
    AddUserForm,
  },
  async created() {
    try {
      await this.$store.dispatch("posts/fetchPosts");
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title: "Une erreur est survenue lors de la récupération de postes",
        text: e.message,
      });
      return;
    }
    this.ready = true;
  },
};
</script>
